<!--  -->
<template>
    <div align="center" class='holter-form'>
        <div id="DomPdf" style="width:794px;height:1123px;">
            <div>
                <span style="display: inline-block; font-size: 24px">
                    八导联24小时心电检测报告
                </span>
                <img style="width: 100px; vertical-align: middle; display: inline-block"
                    src="../../../../public/img/logo_xingou.png" />
            </div>
            <el-row style="border:solid 2px;">
                <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">个人信息
                </div>
                <el-descriptions border :labelStyle="infoStyle">
                    <el-descriptions-item v-for="item in info" :key="item.label" :label="item.label">
                        {{ item.value }}
                    </el-descriptions-item>
                </el-descriptions>
            </el-row>
            <!-- <br> -->
            <el-row style="border:solid 2px;border-bottom: none;border-top: none;">
                <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">分析结果统计
                </div>
            </el-row>
            <el-row>
                <el-col :span="8"
                    style="border-top:solid 2px;border-left:solid 2px;border-bottom:solid 1px;border-right:solid 1px;">
                    <el-descriptions title="概述" :column="1" border :labelStyle="labelStyle">
                        <el-descriptions-item v-for="item in overview" :key="item.label" :label="item.label">
                            {{ item.value }}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-col>
                <el-col :span="8" style="border:solid 1px;border-top: solid 2px; border-bottom:solid 1px">
                    <el-descriptions title="心率" :column="1" border :labelStyle="labelStyle">
                        <el-descriptions-item v-for="item in heartRate" :key="item.label" :label="item.label"
                            class="description-item">
                            {{ item.value }}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-col>
                <el-col :span="8"
                    style="border-top:solid 2px;border-right:solid 2px;border-left:solid 1px;border-bottom: solid 1px;">
                    <el-descriptions title="心率变异" :column="1" border :labelStyle="labelStyle">
                        <el-descriptions-item v-for="item in heartRateAbnorm" :key="item.label" :label="item.label">
                            {{ item.value }}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="8"
                    style="border-bottom:solid 1px;border-left:solid 2px;border-top:solid 1px;border-right:solid 1px; height:290px ">
                    <el-descriptions title="室性节律" :column="1" border :labelStyle="labelStyle">
                        <el-descriptions-item v-for="item in sinusRhythm" :key="item.label" :label="item.label">
                            {{ item.value }}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-col>
                <el-col :span="8" style="border:solid 1px;height:290px ">
                    <el-descriptions title="室上性节律" :column="1" border :labelStyle="labelStyle">
                        <el-descriptions-item v-for="item in supraventricularRhythm" :key="item.label"
                            :label="item.label">
                            {{ item.value }}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-col>
                <el-col :span="8"
                    style="border-bottom:solid 1px;border-right:solid 2px;border-top:solid 1px; border-left:solid 1px;height:290px ">
                    <el-descriptions title="停搏" :column="1" border :labelStyle="labelStyle">
                        <el-descriptions-item v-for="item in asystole" :key="item.label" :label="item.label">
                            {{ item.value }}
                        </el-descriptions-item>
                    </el-descriptions>
                    <br>
                    <el-descriptions title="QT" :column="1" border :labelStyle="labelStyle">
                        <el-descriptions-item v-for="item in qt" :key="item.label" :label="item.label">
                            {{ item.value }}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="24" style="border:solid 2px;border-top: solid 1px;">
                    <el-table :data="tableData" :header-row-style="{ height: '30px' }"
                        :header-cell-style="{ padding: '0px' }" :row-style="{ height: '20px' }"
                        :cell-style="{ padding: '0px' }" style="width: 100%; height: 100%">
                        <el-table-column prop="category" label="类别" width="180">
                        </el-table-column>
                        <el-table-column prop="aVF" label="aVF" width="180">
                        </el-table-column>
                        <el-table-column prop="v5" label="v5" width="180">
                        </el-table-column>
                        <el-table-column prop="v1" label="v1">
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>

            <!-- <br> -->
            <el-row style="border:solid 2px;border-bottom: none;border-top: none;">
                <div style="padding:5px;background-color:rgb(211, 211, 211);font-size: 20px;font-weight: bold;">结论</div>
            </el-row>
            <el-row>
                <el-col :span="24" style="border:solid 2px;border-top: none;">
                    <el-descriptions :column="1" class="description-item" border :labelStyle="labelStyle">
                        <el-descriptions-item v-for="item in conclusion" :key="item.label" :label="item.label">
                            {{ item.value }}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-col>
            </el-row>
        </div>

        <el-button type="primary" plain @click="getPdf('#DomPdf')">下载心电报告</el-button>


    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            deid: 0,

            recordTime: "",
            info: [{ label: '姓名', value: '-' }, { label: '性别', value: '-' }, { label: '年龄', value: '-' },
            { label: '身高', value: '-' }, { label: '体重', value: '-' }],

            overview: [{ label: '分析时间', value: '-' }, { label: '心搏总数', value: '-' }, { label: '起搏心搏', value: '-' }, { label: '室性心搏', value: '0' }
                , { label: '室上性心搏', value: '0' }, { label: 'BBB', value: '0' }, { label: '异常心搏', value: '-' }, { label: '房扑/房颤占时比（%）', value: '' }],
            heartRate: [{ label: '平均心率', value: '-' }, { label: '最慢心率', value: '4873' }, { label: '最快心率', value: '-' }, { label: '分钟计最慢心率', value: '0' }
                , { label: '分钟计最快心率', value: '0' }, { label: '心动过速心搏', value: '0' }, { label: '心动过缓心搏', value: '-' }, { label: '最长RR间期', value: '-' }],
            heartRateAbnorm: [{ label: 'SDNN', value: '-' }, { label: 'SDNN Index', value: '-' }, { label: 'RMSSD', value: '-' }, { label: 'PNN50', value: '0' }
                , { label: '三角指数', value: '0' }, { label: 'HF', value: '0' }, { label: 'LF', value: '-' }, { label: 'VLF', value: '-' }],
            sinusRhythm: [{ label: '单个', value: '-' }, { label: '成对室早', value: '-' }, { label: '二联律室早', value: '-' }, { label: '三联律室早', value: '0' }
                , { label: '连续室早', value: '0' }, { label: '最长连续室早', value: '0' }, { label: '最快心率连续室早', value: '-' }, { label: '最慢心率连续室早', value: '-' }],
            supraventricularRhythm: [{ label: '单个', value: '-' }, { label: '成对室上早', value: '-' }, { label: '二联律室上早', value: '-' }, { label: '三联律室上早', value: '0' }
                , { label: '连续室上早', value: '0' }, { label: '最长连续室上早', value: '0' }, { label: '最快心率连续室上早', value: '-' }, { label: '心房颤动/心房扑动', value: '-' }],
            asystole: [{ label: '停搏大于2秒', value: '-' }, { label: '最长停搏', value: '-' }],
            qt: [{ label: '最大QT', value: '-' }, { label: '最大QTc', value: '0' }, { label: '平均QT', value: '0' }, { label: '平均QTc', value: '0' },],
            conclusion: [{ label: '分析时间', value: '' }, { label: '平均心率', value: '' }, { label: '最大心率', value: '' }, { label: '最小心率', value: '' }, { label: '室性早搏', value: '' }, { label: '综合结论', value: '' }],
            tableData: [{ category: 'ST改变分钟总数', aVF: "", V5: "", V1: "" }, { category: '最大ST压低mm', aVF: "", V5: "", V1: "" }, { category: '最大ST抬高mm', aVF: "", V5: "", V1: "" }],
            htmlTitle: '24小时心电检测报告',
            labelStyle: {
                'width': '180px',
                'color': '#606266'
            },
            infoStyle: {
                'color': '#606266'
            }
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        getReportParams() {
            if (this.$store.state.module.crossPlatform) {
                console.log("object11111");
                this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
            }
            this.$http.adornHeader(this.header)
            this.$http({
                url: this.$http.adornUrl('/personal/info/getBasicInfo'),
                method: 'get',
                params: this.$http.adornParams({ uid: this.$store.state.personal_module.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log('看看身份信息')
                    console.log(data)
                    this.info[0]["value"] = data.realName
                    this.info[2]["value"] = data.age

                    var genderMap = { 0: "女", 1: "男" }
                    this.info[1]["value"] = genderMap[data.gender]
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })


            // 根据每条心电记录的deid获取该条的结论
            console.log(this.deid)
            this.info[3]["value"] = this.recordTime




            this.$http({
                url: this.$http.adornUrl('/personal/dynamicecg8/app/getReportEight'),
                method: 'post',
                params: this.$http.adornParams({
                    deid: this.deid,
                }),
            }).then(({ data }) => {
                if (data && data.code === 0 && data.msg === "success") {

                    console.log("看看数据")
                    console.log(data)

                    this.conclusionData = data.conclusionData
                    let module_ls = [this.overview, this.heartRate, this.heartRateAbnorm,
                    this.sinusRhythm, this.supraventricularRhythm, this.qt, this.conclusion]
                    let name_ls = ["概述", "心率", "心率变异", "室性节律", "室上性节律", "QT", "结论"]
                    this.info[4]["value"] = this.conclusionData["概述"]["分析时间"]

                    // 赋值
                    for (let i = 0; i < module_ls.length; i++) {

                        for (let j = 0; j < module_ls[i].length; j++) {
                            let label = module_ls[i][j]["label"]
                            if (!(label in this.conclusionData[name_ls[i]])) {
                                module_ls[i][j]["value"] = "-"
                                continue
                            }
                            module_ls[i][j]["value"] = this.conclusionData[name_ls[i]][label]
                        }
                    }


                    //  // 心率
                    // for(let i = 0; i < this.heartRate.length; i++){
                    //     let label = this.heartRate[i]["label"]
                    //     if(!(label in this.conclusionData["心率"])) {
                    //         this.heartRate[i]["value"] = "-"
                    //         continue
                    //     }
                    //     this.heartRate[i]["value"] = this.conclusionData["心率"][label]
                    // }


                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.deid = this.$route.query.dynamicRecordId
        this.recordTime = this.$route.query.dynamicRecordCreateTime
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.getReportParams()
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less" scoped>
.holter-form /deep/ .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    padding: 5px
}

::v-deep .el-descriptions__title {
    font-size: 16px;
}

::v-deep .el-descriptions__header {
    display: flex;
    justify-content: space-between;
    margin: 5px;

}
</style>